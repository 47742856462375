import { IconButton, IconButtonProps, styled } from "@mui/material";
import { Menu } from "@mui/icons-material";
import React, { FC } from "react";

const MenuIcon = styled(Menu)(({ theme }) => ({
  width: "35px",
  height: "35px",
  color: theme.palette.icons.main,
}));

export interface HamburgerButtonProps extends IconButtonProps {}

export const HamburgerButton: FC<HamburgerButtonProps> = ({
  ...containerProps
}) => {
  return (
    <IconButton {...containerProps} data-testid="menu-drawer-button">
      <MenuIcon data-testid="menu-drawer-icon" />
    </IconButton>
  );
};
