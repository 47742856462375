import { Button, ButtonProps, styled } from "@mui/material";

const PaddedButton = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(8)}`,
  textTransform: "none",
  fontSize: `1rem`,
}));

export interface RectangleButtonProps extends ButtonProps {}

export function RectangleButton({ ...containerProps }: RectangleButtonProps) {
  const { children } = containerProps;

  return (
    <PaddedButton variant="contained" {...containerProps}>
      {children}
    </PaddedButton>
  );
}
