import React, { FC, useEffect, useState } from "react";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { LoadingSpinner } from "components/LoadingSpinner";

interface MagicLinkAuthProps {
  children: any;
}

/**
 * Magic link Auth
 * @description Auth for users logging in with magic links
 */
export const MagicLinkAuth: FC<MagicLinkAuthProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function handleAuth() {
      const auth = getAuth();
      const emailFromURL =
        new URLSearchParams(window.location.search).get("email") ?? "";

      const isMagicLink = isSignInWithEmailLink(auth, window.location.href);

      if (emailFromURL && isMagicLink) {
        setLoading(true);
        try {
          // Note: magic links only work to login once. Multiple attempts will
          // result in a generic "(auth/invalid-action-code)" error from Firebase.
          await signInWithEmailLink(auth, emailFromURL, window.location.href);
        } catch (error) {
          // If login fails it is the child pages responsibility to redirect if
          // required. User may be logged in already and nothing will be required.
          console.error(error);
        }
      }
      setLoading(false);
    }

    handleAuth();
  }, []);

  // No email parameter in a magic link URL will result in a generic auth error

  return loading ? <LoadingSpinner /> : props.children;
};
