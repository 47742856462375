import { ContractorAbilities } from "models/Contractor";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ContractorDto,
  useContractorsControllerFindAllQuery,
} from "services/buildAPIService";
import { setContractor } from "state/slices/contractor/contractorSlice";
import { selectCurrentUser } from "state/slices/session/sessionSlice";
import { deepEqual } from "@firebase/util";
import { store } from "state/store";

export interface UseManageUserContractorState {
  (pollingInterval?: number): void;
}

/**
 * Automagically fetches contractor data for the logged in user and
 * updates the contractor state slice on auth state change. Will periodically
 * poll for updates to the targeted contractor record
 *
 * @param pollingInterval interval in milliseconds, defaults to every 1 minute, 0 disables polling
 */
const useManageUserContractorState: UseManageUserContractorState = (
  pollingInterval = 1000 * 60
) => {
  // TODO: this code needs testing
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const previousDataRef = useRef<ContractorDto | null>(null);
  const { data, error } = useContractorsControllerFindAllQuery(
    { userId: user?.uid ?? "" },
    {
      skip: !user?.uid,
      refetchOnMountOrArgChange: true,
      pollingInterval: pollingInterval,
    }
  );

  useEffect(handleContractorDataChange, [data, dispatch]);
  useEffect(handleError, [error]);
  useEffect(handleLogout, [dispatch, user]);

  function handleContractorDataChange() {
    if (!data?.length) {
      return;
    }

    const dto = data[0];
    // don't update state if data from refetch is identical
    if (previousDataRef.current && deepEqual(previousDataRef.current, dto)) {
      return;
    }

    dispatch(
      setContractor({
        data: {
          ...dto,
          abilities: dto.abilities as ContractorAbilities[],
        },
      })
    );

    previousDataRef.current = dto;
  }

  function handleError() {
    // TODO: organized error logging
    if (error) {
      console.error("failed to fetch user contractor", error);
    }
  }

  function handleLogout() {
    if (!user && store.getState().contractor.data) {
      dispatch(setContractor({ data: null }));
    }
  }
};

export { useManageUserContractorState };
export default useManageUserContractorState;
