import { buildAPI as api } from "../api/buildAPI";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    liisaControllerHealthcheck: build.query<
      LiisaControllerHealthcheckApiResponse,
      LiisaControllerHealthcheckApiArg
    >({
      query: () => ({ url: `/v1/healthcheck` }),
    }),
    clientProfilesControllerFindAll: build.query<
      ClientProfilesControllerFindAllApiResponse,
      ClientProfilesControllerFindAllApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/client-profiles/list`,
        params: {
          fromdt: queryArg.fromdt,
          todt: queryArg.todt,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
          testAccts: queryArg.testAccts,
          isDesigner: queryArg.isDesigner,
          status: queryArg.status,
          sortDirection: queryArg.sortDirection,
          search: queryArg.search,
        },
      }),
    }),
    contractorsControllerCreate: build.mutation<
      ContractorsControllerCreateApiResponse,
      ContractorsControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contractors`,
        method: "POST",
        body: queryArg.createContractorDto,
      }),
    }),
    contractorsControllerFindAll: build.query<
      ContractorsControllerFindAllApiResponse,
      ContractorsControllerFindAllApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contractors`,
        params: {
          page: queryArg.page,
          pageSize: queryArg.pageSize,
          firestoreUuid: queryArg.firestoreUuid,
          userId: queryArg.userId,
        },
      }),
    }),
    contractorsControllerFindOne: build.query<
      ContractorsControllerFindOneApiResponse,
      ContractorsControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/v1/contractors/${queryArg.id}` }),
    }),
    contractorsControllerUpdate: build.mutation<
      ContractorsControllerUpdateApiResponse,
      ContractorsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contractors/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateContractorDto,
      }),
    }),
    contractorsControllerRemove: build.mutation<
      ContractorsControllerRemoveApiResponse,
      ContractorsControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contractors/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    buildProjectsControllerCreate: build.mutation<
      BuildProjectsControllerCreateApiResponse,
      BuildProjectsControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/build-projects`,
        method: "POST",
        body: queryArg.createBuildProjectDto,
      }),
    }),
    buildProjectsControllerFindAll: build.query<
      BuildProjectsControllerFindAllApiResponse,
      BuildProjectsControllerFindAllApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/build-projects`,
        params: {
          sortDirection: queryArg.sortDirection,
          sortBy: queryArg.sortBy,
          leadStatus: queryArg.leadStatus,
          buildStage: queryArg.buildStage,
          contractorId: queryArg.contractorId,
          profileId: queryArg.profileId,
          buildProjectId: queryArg.buildProjectId,
          includeDeleted: queryArg.includeDeleted,
          fromDate: queryArg.fromDate,
          toDate: queryArg.toDate,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
          search: queryArg.search,
        },
      }),
    }),
    buildProjectsControllerFindOne: build.query<
      BuildProjectsControllerFindOneApiResponse,
      BuildProjectsControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/v1/build-projects/${queryArg.id}` }),
    }),
    buildProjectsControllerUpdate: build.mutation<
      BuildProjectsControllerUpdateApiResponse,
      BuildProjectsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/build-projects/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateBuildProjectDto,
      }),
    }),
    buildProjectsControllerRemove: build.mutation<
      BuildProjectsControllerRemoveApiResponse,
      BuildProjectsControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/build-projects/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    leadsControllerCreate: build.mutation<
      LeadsControllerCreateApiResponse,
      LeadsControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/leads`,
        method: "POST",
        body: queryArg.createLeadDto,
      }),
    }),
    leadsControllerFindAll: build.query<
      LeadsControllerFindAllApiResponse,
      LeadsControllerFindAllApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/leads`,
        params: {
          sortDirection: queryArg.sortDirection,
          status: queryArg.status,
        },
      }),
    }),
    leadsControllerFindOne: build.query<
      LeadsControllerFindOneApiResponse,
      LeadsControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/v1/leads/${queryArg.id}` }),
    }),
    leadsControllerUpdate: build.mutation<
      LeadsControllerUpdateApiResponse,
      LeadsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/leads/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateLeadDto,
      }),
    }),
    leadsControllerRemove: build.mutation<
      LeadsControllerRemoveApiResponse,
      LeadsControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/leads/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    leadsControllerCreateLeadStatusChange: build.mutation<
      LeadsControllerCreateLeadStatusChangeApiResponse,
      LeadsControllerCreateLeadStatusChangeApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/leads/${queryArg.id}/status`,
        method: "POST",
        body: queryArg.createLeadStatusChangeDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as buildAPIService };
export type LiisaControllerHealthcheckApiResponse = /** status 200  */ string;
export type LiisaControllerHealthcheckApiArg = void;
export type ClientProfilesControllerFindAllApiResponse =
  /** status 200  */ ClientProfile[];
export type ClientProfilesControllerFindAllApiArg = {
  /** From date in ISO string format (`new Date().toISOString()`). Defaults to 3 months ago. */
  fromdt?: string;
  /** To date in ISO string format (`new Date().toISOString()`). Defaults to now. */
  todt?: string;
  /** For pagination purposes. The page number to be fetched. */
  page?: string;
  /** Number of results to return per page. */
  pageSize?: string;
  /** Include test accounts. */
  testAccts?: string;
  /** Include designer accounts. */
  isDesigner?: string;
  /** Maps to Status dropdown options. */
  status?:
    | "materialsRecieved"
    | "reviewDesign"
    | "clientReview"
    | "drafting"
    | "designerActionable"
    | "feedbackRecieved";
  /** 1 for ASC and -1 for DESC. */
  sortDirection?: "1" | "-1";
  /** String that user is searching for. Valid entries include firstName, lastName, email, and address. */
  search?: string;
};
export type ContractorsControllerCreateApiResponse =
  /** status 201  */ ContractorDto;
export type ContractorsControllerCreateApiArg = {
  createContractorDto: CreateContractorDto;
};
export type ContractorsControllerFindAllApiResponse =
  /** status 200  */ ContractorDto[];
export type ContractorsControllerFindAllApiArg = {
  /** For pagination purposes. The page number to be fetched. */
  page?: string;
  /** Number of results to return per page. */
  pageSize?: string;
  /** String corresponding to the firestore document id for a contractor */
  firestoreUuid?: string;
  /** String that is the user id for the user account associated with a contractor record */
  userId?: string;
};
export type ContractorsControllerFindOneApiResponse =
  /** status 200  */ ContractorDto;
export type ContractorsControllerFindOneApiArg = {
  id: string;
};
export type ContractorsControllerUpdateApiResponse =
  /** status 200  */ ContractorDto;
export type ContractorsControllerUpdateApiArg = {
  id: string;
  updateContractorDto: UpdateContractorDto;
};
export type ContractorsControllerRemoveApiResponse =
  /** status 200  */ StatusResponseDto;
export type ContractorsControllerRemoveApiArg = {
  id: string;
};
export type BuildProjectsControllerCreateApiResponse =
  /** status 201  */ BuildProjectBasicDto;
export type BuildProjectsControllerCreateApiArg = {
  createBuildProjectDto: CreateBuildProjectDto;
};
export type BuildProjectsControllerFindAllApiResponse =
  /** status 200  */ BuildProjectDetailedDto[];
export type BuildProjectsControllerFindAllApiArg = {
  /** 1 for ASC and -1 for DESC. */
  sortDirection?: "1" | "-1";
  /** Define property to sort by. Will use lead date if exists and resort to build project date. */
  sortBy?: "updatedAt" | "createdAt";
  /** Filters for specific lead status. */
  leadStatus?:
    | "Contractor Status"
    | "Client Status"
    | "Intro Sent"
    | "Site Walk Scheduled"
    | "Site Walk Completed"
    | "Revisions Requested"
    | "Estimate Delivered"
    | "Client Reported Contract Signed"
    | "Contract Signed"
    | "Official Contract Signed"
    | "Project Started"
    | "Project Completed"
    | "Ready for Invoice"
    | "Invoice Sent"
    | "Invoice Paid"
    | "Project Not Moving Forward"
    | "Lost"
    | "Hold";
  /** Filters for specific build stage. */
  buildStage?:
    | "Projects for Prospect"
    | "Ready for Pro Match"
    | "Waiting for Build Confirm"
    | "Sent for Review"
    | "Intro Sent"
    | "Site Walk Scheduled"
    | "Site Walk Completed"
    | "Revisions Requested"
    | "Estimate Delivered"
    | "Client Reported Contract Signed"
    | "Contract Signed"
    | "Official Contract Signed"
    | "Project Started"
    | "Project Completed"
    | "Ready for Invoice"
    | "Invoice Sent"
    | "Invoice Paid"
    | "Project Not Moving Forward"
    | "Lost"
    | "Hold";
  /** Filters for a specific contractor. */
  contractorId?: string;
  /** Filters for a specific client. */
  profileId?: string;
  /** Filters for a specific build project. */
  buildProjectId?: string;
  /** Include deleted leads. */
  includeDeleted?: string;
  /** From date in ISO string format (`new Date().toISOString()`). */
  fromDate?: string;
  /** To date in ISO string format (`new Date().toISOString()`). */
  toDate?: string;
  /** For pagination purposes. The page number to be fetched. */
  page?: string;
  /** Number of results to return per page. */
  pageSize?: number;
  /** Searches on client's firstName, lastName, email, and street address as well as contractor's business name. */
  search?: string;
};
export type BuildProjectsControllerFindOneApiResponse =
  /** status 200  */ BuildProjectDetailedDto;
export type BuildProjectsControllerFindOneApiArg = {
  id: number;
};
export type BuildProjectsControllerUpdateApiResponse =
  /** status 200  */ BuildProjectBasicDto;
export type BuildProjectsControllerUpdateApiArg = {
  id: string;
  updateBuildProjectDto: UpdateBuildProjectDto;
};
export type BuildProjectsControllerRemoveApiResponse =
  /** status 200  */ StatusResponseDto;
export type BuildProjectsControllerRemoveApiArg = {
  id: string;
};
export type LeadsControllerCreateApiResponse = /** status 201  */ LeadDto;
export type LeadsControllerCreateApiArg = {
  createLeadDto: CreateLeadDto;
};
export type LeadsControllerFindAllApiResponse = /** status 200  */ LeadDto[];
export type LeadsControllerFindAllApiArg = {
  /** 1 for ASC and -1 for DESC. */
  sortDirection?: "1" | "-1";
  /** Maps to Status dropdown options. */
  status?:
    | "Contractor Status"
    | "Client Status"
    | "Intro Sent"
    | "Site Walk Scheduled"
    | "Site Walk Completed"
    | "Revisions Requested"
    | "Estimate Delivered"
    | "Client Reported Contract Signed"
    | "Contract Signed"
    | "Official Contract Signed"
    | "Project Started"
    | "Project Completed"
    | "Ready for Invoice"
    | "Invoice Sent"
    | "Invoice Paid"
    | "Project Not Moving Forward"
    | "Lost"
    | "Hold";
};
export type LeadsControllerFindOneApiResponse = /** status 200  */ LeadDto;
export type LeadsControllerFindOneApiArg = {
  id: string;
};
export type LeadsControllerUpdateApiResponse =
  /** status 200  */ StatusResponseDto;
export type LeadsControllerUpdateApiArg = {
  id: string;
  updateLeadDto: UpdateLeadDto;
};
export type LeadsControllerRemoveApiResponse =
  /** status 200  */ StatusResponseDto;
export type LeadsControllerRemoveApiArg = {
  id: string;
};
export type LeadsControllerCreateLeadStatusChangeApiResponse =
  /** status 201  */ LeadStatusChangeDto;
export type LeadsControllerCreateLeadStatusChangeApiArg = {
  id: string;
  createLeadStatusChangeDto: CreateLeadStatusChangeDto;
};
export type ClientProfile = {};
export type ContractorDto = {
  id: number;
  firestoreUuid: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  deletedBy: string | null;
  businessName: string;
  licenseNumber: string | null;
  firstName: string | null;
  lastName: string | null;
  streetLineOne: string | null;
  streetLineTwo: string | null;
  city: string;
  state: string;
  zip: string | null;
  country: string | null;
  phone: string;
  email: string;
  website: string | null;
  pointOfContactName: string | null;
  pointOfContactEmail: string | null;
  pointOfContactPhone: string | null;
  notes: string | null;
  preferred: boolean;
  isActive: boolean;
  abilities: (
    | "Woodwork"
    | "Metal Work"
    | "Pergolas - Composite, Metal, Kits"
    | "Decking - Composite"
    | "Lighting - Low Voltage"
    | "Masonry"
    | "Turf"
    | "Pavers"
    | "Concrete"
    | "Outdoor Kitchens"
    | "Outdoor Showers"
    | "Water Features/Ponds"
    | "CA Rooms"
    | "Pools/Spas - Custom"
    | "Pools/Spas - Pre Fab"
    | "Sheds/ADUs"
    | "Botanical Only"
    | "Exterior"
  )[];
  coordinates: {
    lat: number;
    lon: number;
  };
  bsatScore: number;
};
export type CoordinatesDto = {
  lat: number;
  lon: number;
};
export type CreateContractorDto = {
  userId: string;
  businessName: string;
  city: string;
  state: string;
  phone: string;
  email: string;
  preferred: boolean;
  isActive: boolean;
  abilities?: (
    | "Woodwork"
    | "Metal Work"
    | "Pergolas - Composite, Metal, Kits"
    | "Decking - Composite"
    | "Lighting - Low Voltage"
    | "Masonry"
    | "Turf"
    | "Pavers"
    | "Concrete"
    | "Outdoor Kitchens"
    | "Outdoor Showers"
    | "Water Features/Ponds"
    | "CA Rooms"
    | "Pools/Spas - Custom"
    | "Pools/Spas - Pre Fab"
    | "Sheds/ADUs"
    | "Botanical Only"
    | "Exterior"
  )[];
  coordinates?: CoordinatesDto;
  licenseNumber?: string;
  firstName?: string;
  lastName?: string;
  streetLineOne?: string;
  streetLineTwo?: string;
  zip?: string;
  country?: string;
  website?: string;
  pointOfContactName?: string;
  pointOfContactEmail?: string;
  pointOfContactPhone?: string;
  notes?: string;
};
export type UpdateContractorDto = {
  coordinates?: object;
  userId?: string;
  businessName?: string;
  city?: string;
  state?: string;
  phone?: string;
  email?: string;
  preferred?: boolean;
  isActive?: boolean;
  abilities?: (
    | "Woodwork"
    | "Metal Work"
    | "Pergolas - Composite, Metal, Kits"
    | "Decking - Composite"
    | "Lighting - Low Voltage"
    | "Masonry"
    | "Turf"
    | "Pavers"
    | "Concrete"
    | "Outdoor Kitchens"
    | "Outdoor Showers"
    | "Water Features/Ponds"
    | "CA Rooms"
    | "Pools/Spas - Custom"
    | "Pools/Spas - Pre Fab"
    | "Sheds/ADUs"
    | "Botanical Only"
    | "Exterior"
  )[];
  licenseNumber?: string;
  firstName?: string;
  lastName?: string;
  streetLineOne?: string;
  streetLineTwo?: string;
  zip?: string;
  country?: string;
  website?: string;
  pointOfContactName?: string;
  pointOfContactEmail?: string;
  pointOfContactPhone?: string;
  notes?: string;
};
export type StatusResponseDto = {
  message: string;
  statusCode: number;
};
export type BuildProjectBasicDto = {
  profileId: string;
  revisionId: string;
  projectId: string;
  buildStage: string;
  id: number;
  createdAt: string;
  updatedAt: string;
};
export type CreateBuildProjectDto = {
  profileId: string;
  revisionId: string;
  projectId: string;
  buildStage: string;
};
export type LeadWithLatestStatusChange = {
  status: string;
  change?: {
    status: string;
    updatedAt: string;
  };
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  bsatScore: number;
  isLatestBuild: boolean;
};
export type BuildProjectDetailedDto = {
  buildProject: BuildProjectBasicDto;
  client: {
    id: string;
    firstName: string;
    lastName: string;
    streetLineOne: string;
    streetLineTwo: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    phone: string;
    email: string;
    coordinates: object | null;
    adjustedBudget: number;
  };
  contractor: {
    id: number;
    firestoreUuid: string;
    businessName: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  lead: LeadWithLatestStatusChange;
};
export type UpdateBuildProjectDto = {
  profileId?: string;
  revisionId?: string;
  projectId?: string;
  buildStage?: string;
};
export type LeadDto = {};
export type CreateLeadDto = {
  contractorId: number;
  buildProjectId: number;
};
export type UpdateLeadDto = {
  contractorId?: number;
  buildProjectId?: number;
  bsatScore?: number;
};
export type LeadStatusChangeDto = {
  changeType:
    | "Contractor Status"
    | "Client Status"
    | "Intro Sent"
    | "Site Walk Scheduled"
    | "Site Walk Completed"
    | "Revisions Requested"
    | "Estimate Delivered"
    | "Client Reported Contract Signed"
    | "Contract Signed"
    | "Official Contract Signed"
    | "Project Started"
    | "Project Completed"
    | "Ready for Invoice"
    | "Invoice Sent"
    | "Invoice Paid"
    | "Project Not Moving Forward"
    | "Lost"
    | "Hold";
  change: {
    status: "yes" | "no";
    context?: string;
    reason?: string;
  };
  id: number;
  createdAt: string;
  updatedAt: string;
  leadId: number;
  buildProjectLeadStatusId: number;
};
export type CreateLeadStatusChangeDto = {
  changeType:
    | "Contractor Status"
    | "Client Status"
    | "Intro Sent"
    | "Site Walk Scheduled"
    | "Site Walk Completed"
    | "Revisions Requested"
    | "Estimate Delivered"
    | "Client Reported Contract Signed"
    | "Contract Signed"
    | "Official Contract Signed"
    | "Project Started"
    | "Project Completed"
    | "Ready for Invoice"
    | "Invoice Sent"
    | "Invoice Paid"
    | "Project Not Moving Forward"
    | "Lost"
    | "Hold";
  change: {
    status: "yes" | "no";
    context?: string;
    reason?: string;
  };
  createdBy: "contractor" | "client";
};
export const {
  useLiisaControllerHealthcheckQuery,
  useClientProfilesControllerFindAllQuery,
  useContractorsControllerCreateMutation,
  useContractorsControllerFindAllQuery,
  useContractorsControllerFindOneQuery,
  useContractorsControllerUpdateMutation,
  useContractorsControllerRemoveMutation,
  useBuildProjectsControllerCreateMutation,
  useBuildProjectsControllerFindAllQuery,
  useBuildProjectsControllerFindOneQuery,
  useBuildProjectsControllerUpdateMutation,
  useBuildProjectsControllerRemoveMutation,
  useLeadsControllerCreateMutation,
  useLeadsControllerFindAllQuery,
  useLeadsControllerFindOneQuery,
  useLeadsControllerUpdateMutation,
  useLeadsControllerRemoveMutation,
  useLeadsControllerCreateLeadStatusChangeMutation,
} = injectedRtkApi;
