import { Drawer, DrawerProps } from "@mui/material";
import { ReactNode } from "react";
import {
  DrawerContainer,
  CloseIconButton,
  CloseIcon,
} from "components/layout/TemporaryDrawerMenu/TemporaryDrawerMenu.styles";

export interface TemporaryDrawerMenuProps {
  children?: ReactNode;
  anchor?: DrawerProps["anchor"];
  open: boolean;
  onClose?: () => void;
}

/**
 * A toggleable drawer menu component.
 * @param props The child react components to render within the drawer.
 * @returns A toggleable drawer menu.
 */
export function TemporaryDrawerMenu({
  children,
  anchor,
  open,
  onClose: handleClose,
}: TemporaryDrawerMenuProps) {
  return (
    <Drawer
      variant="temporary"
      anchor={anchor}
      open={open}
      onClose={handleClose}
      data-testid="menu-drawer-container"
    >
      <DrawerContainer>
        <CloseIconButton
          data-testid="close-menu-drawer-button"
          onClick={handleClose}
        >
          <CloseIcon data-testid="close-menu-drawer-icon" />
        </CloseIconButton>
        {children}
      </DrawerContainer>
    </Drawer>
  );
}
