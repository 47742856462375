import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const HeaderContainer = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px",
    borderBottom: `1px solid ${theme.palette.grays.light}`,
  };
});

const LogoImage = styled("img")(() => ({
  width: "125px",
}));

export { LogoImage, HeaderContainer };
