import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Contractor } from "models/Contractor";

interface ContractorState {
  data: Contractor | null;
}

export const initialContractorState: ContractorState = { data: null };

export const contractorSlice = createSlice({
  name: "contractor",
  initialState: initialContractorState,
  reducers: {
    setContractor: (state, action: PayloadAction<Partial<ContractorState>>) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      } else {
        state.data = null;
      }
    },
  },
});

export const { setContractor } = contractorSlice.actions;
export const selectContractor = (state: RootState) => state.contractor.data;
export const contractorReducer = contractorSlice.reducer;
