import React from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { PageLayout } from "components/layout";
import { theme } from "./utils/styles/theme";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "routes";
import { useUpdateSessionStateOnAuthChange } from "utils/hooks/useUpdateSessionStateOnAuthChange";
import { MagicLinkAuth } from "components/auth/MagicLinkAuth";
import useManageUserContractorState from "utils/hooks/useManageUserContractorState";

function App() {
  useUpdateSessionStateOnAuthChange();
  useManageUserContractorState();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <BrowserRouter>
          <PageLayout>
            <MagicLinkAuth>
              <AppRoutes />
            </MagicLinkAuth>
          </PageLayout>
        </BrowserRouter>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
