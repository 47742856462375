import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface SessionState {
  currentUser: {
    email: string | null;
    uid: string;
    token: string;
  } | null;
}

const initialState: SessionState = { currentUser: null };

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<Partial<SessionState>>) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      } else {
        state.currentUser = null;
      }
    },
  },
});

export const { setCurrentUser } = sessionSlice.actions;
export const selectCurrentUser = (state: RootState) =>
  state.session.currentUser;
export const sessionReducer = sessionSlice.reducer;
