import { Box, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const DrawerContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  minWidth: "225px",
  padding: "6px 6px 24px 24px",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  alignSelf: "flex-end",
  marginBottom: "12px",
  color: theme.palette.icons.main,
}));

const CloseIcon = styled(Close)(() => ({
  width: "30px",
  height: "30px",
}));

export { DrawerContainer, CloseIconButton, CloseIcon };
