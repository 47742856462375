import { useEffect } from "react";
import { getAuth, User } from "firebase/auth";

export interface UseOnAuthStateChanged {
  (handler: (user: User | null) => any): void;
}

/**
 * a Hook wrapper for the firebase auth `onIdTokenChanged` event-propagator
 * @param handler - The function to be run on id token refresh or auth state change. **This should be memoized**
 */
const useOnAuthStateChanged: UseOnAuthStateChanged = (handler) => {
  useEffect(applyHandler, [handler]);

  function applyHandler() {
    const auth = getAuth();
    return auth.onIdTokenChanged(handler);
  }
};

export { useOnAuthStateChanged };
